import { useNavigate } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { HealthMeasurementValueRangeColors } from "../../../../theme";
import BottomNavigation from "../../../Common/components/BottomNavigation";
import Panel from "../../../Common/components/Panel";
import HealthMeasurementDetailModal from "../HealthMeasurementDetailModal";
import { useContext, useEffect, useState } from "react";
import {
  getHealthMeasurementValueRange,
  getHealthProfile,
  HealthMeasurementDataValueRange,
  HealthMeasurementQuestionType,
} from "../../../../services/core-api-adapter";
import HealthMeasurementDetailVerificationStatus from "../HealthMeasurementDetailVerificationStatus";
import HealthMeasurementGauge from "../HealthMeasurementGauge";
import { convertStringToJSON } from "../../../../utils";
import { SnackbarContext } from "../../../../context/SnackbarProvider";
import HealthMeasurementDetailUpdate from "../HealthMeasurementDetailUpdate";
import IconLoader from "../../../Common/components/IconLoader";
import { HealthMeasurementIdentifier } from "../../../../store";
import HealthMeasurementDetailHeader from "./components/HealthMeasurementDetailHeader";
import LoadingSpinner from "../../../Common/components/LoadingSpinner";

interface HealthMeasurementDetailProps {
  healthMeasurementIdentifier: HealthMeasurementQuestionType;
}

export interface HealthMeasurementDetailMetadataItem {
  shouldDisplay: boolean;
  questionType: HealthMeasurementQuestionType;
  questionId?: string;
  initialValue?: any;
  currentValue?: any;
  unitOverride?: string;
  valueAsJSON?: any;
  isValid?: boolean;
  updateComponentState?: HealthMeasurementMetadataUpdateState;
  numberOfTicks?: number;
  lastUpdated?: string;
  originatedFrom?: string;
  gaugeInfoModal?: {
    isDisplayed: boolean;
    isFooterContentVisible: boolean;
  };
  isVerified?: boolean;
  isUserEditable?: boolean;
}

interface HealthMeasurementMetadataUpdateState {
  isUpdateButtonVisible: boolean;
  isValueVisible: boolean;
  updateViewIcon: any;
}

interface HealthMeasurementMetadataItem {
  items: HealthMeasurementDetailMetadataItem[];
  displaySubHeading?: boolean;
}

interface HealthMeasurementsMetadata {
  [key: string]: HealthMeasurementMetadataItem;
}

const healthMeasurementsMetadata: HealthMeasurementsMetadata = {
  BloodPressure: {
    items: [
      {
        questionType: "SystolicBloodPressure",
        numberOfTicks: 71,
        shouldDisplay: true,
      },
      {
        questionType: "DiastolicBloodPressure",
        numberOfTicks: 76,
        shouldDisplay: true,
      },
    ],
  },
  BodyMassIndex: {
    items: [
      {
        questionType: "BodyMassIndex",
        numberOfTicks: 41,
        shouldDisplay: true,
      },
    ],
  },
  MetabolicAge: {
    items: [
      {
        questionType: "MetabolicAge",
        numberOfTicks: 17,
        shouldDisplay: true,
      },
    ],
  },
  BodyWaterPercent: {
    items: [
      {
        questionType: "BodyWaterPercent",
        numberOfTicks: 61,
        shouldDisplay: true,
      },
    ],
  },
  BodyFat: {
    items: [
      {
        questionType: "BodyFat",
        numberOfTicks: 25,
        shouldDisplay: true,
      },
    ],
  },
  Pulse: {
    items: [
      {
        questionType: "Pulse",
        shouldDisplay: true,
        updateComponentState: {
          isUpdateButtonVisible: false,
          isValueVisible: true,
          updateViewIcon: <IconLoader icon="PulseIcon" color="primary" />,
        },
      },
    ],
  },
  SaturationOfPeripheralOxygen: {
    items: [
      {
        questionType: "SaturationOfPeripheralOxygen",
        shouldDisplay: true,
        updateComponentState: {
          isUpdateButtonVisible: false,
          isValueVisible: true,
          updateViewIcon: <IconLoader icon="HeartIcon" color="primary" />,
        },
      },
    ],
  },
  Weight: {
    items: [
      {
        questionType: "Weight",
        shouldDisplay: true,
        updateComponentState: {
          isUpdateButtonVisible: true,
          isValueVisible: true,
          updateViewIcon: <IconLoader icon="ScaleIcon" color="primary" />,
        },
      },
    ],
  },
  BloodType: {
    items: [
      {
        questionType: "BloodType",
        shouldDisplay: true,
        unitOverride: "",
        updateComponentState: {
          isUpdateButtonVisible: true,
          isValueVisible: true,
          updateViewIcon: <IconLoader icon="DropIcon" color="primary" />,
        },
      },
    ],
  },
  SmokingStatusAndFrequency: {
    items: [
      {
        questionType: "SmokingFrequency",
        shouldDisplay: true,
        updateComponentState: {
          isUpdateButtonVisible: true,
          isValueVisible: true,
          updateViewIcon: <IconLoader icon="HeartIcon" color="primary" />,
        },
      },
    ],
  },
  Height: {
    items: [
      {
        questionType: "Height",
        shouldDisplay: true,
        updateComponentState: {
          isUpdateButtonVisible: true,
          isValueVisible: true,
          updateViewIcon: <IconLoader icon="DropIcon" color="primary" />,
        },
      },
    ],
  },
  BreathingRate: {
    items: [
      {
        questionType: "BreathingRate",
        shouldDisplay: true,
        updateComponentState: {
          isUpdateButtonVisible: false,
          isValueVisible: true,
          updateViewIcon: (
            <IconLoader icon="BreathingRateIcon" color="primary" />
          ),
        },
      },
    ],
  },
};

function replaceValuesWithColors(
  valueRange: HealthMeasurementDataValueRange[],
  key: HealthMeasurementQuestionType
) {
  return valueRange.map((item) => {
    return {
      ...item,
      color: HealthMeasurementValueRangeColors[key][item.healthRiskIndicator],
    };
  });
}

function getIsUserEditableStatus(
  healthMeasurementData: HealthMeasurementDetailMetadataItem[] | undefined
) {
  return (
    healthMeasurementData?.find((healthMeasurementItem) => {
      return "isUserEditable" in healthMeasurementItem;
    })?.isUserEditable || false
  );
}

export default function HealthMeasurementDetail({
  healthMeasurementIdentifier,
}: HealthMeasurementDetailProps) {
  const navigate = useNavigate();
  const [healthMeasurementMetadata, setHealthMeasurementMetadata] =
    useState<HealthMeasurementMetadataItem | null>(null);

  const [gaugeInfoModalState, setGaugeInfoModalState] = useState<{
    isOpen: boolean;
    questionType: HealthMeasurementQuestionType | null;
    isFooterContentVisible: boolean;
  }>({ isOpen: false, questionType: null, isFooterContentVisible: false });

  function openInfoModal(
    questionType: HealthMeasurementQuestionType,
    isFooterContentVisible: boolean = false
  ) {
    setGaugeInfoModalState({
      isOpen: true,
      questionType,
      isFooterContentVisible,
    });
  }

  function closeInfoModal() {
    setGaugeInfoModalState({
      isOpen: false,
      questionType: null,
      isFooterContentVisible: false,
    });
  }

  const [isFetchingHealthProfile, setIsFetchingHealthProfile] = useState(false);
  const { t } = useTranslation();
  const { showSnackbar } = useContext(SnackbarContext);

  function getSmokingStatusAndValue(
    healthMeasurementItem: HealthMeasurementDetailMetadataItem,
    response: any
  ) {
    const newHealthMeasurementItem = { ...healthMeasurementItem };

    const smokingStatusHealthMeasurement = response?.items?.find(
      (item: any) => {
        return item.questionType === "SmokingStatus";
      }
    );

    const smokingStatusNormalised = String(
      JSON.parse(smokingStatusHealthMeasurement?.data)
    ).toLowerCase();

    if (
      smokingStatusNormalised === "yes" &&
      newHealthMeasurementItem?.valueAsJSON?.interval &&
      newHealthMeasurementItem?.valueAsJSON?.value
    ) {
      newHealthMeasurementItem.valueAsJSON.unit = t(
        `HealthProfile.SmokingFrequency.${newHealthMeasurementItem.valueAsJSON?.interval}` as any,
        {
          value: "",
        }
      );
    } else {
      newHealthMeasurementItem.valueAsJSON = {
        value: "0",
        unit: t(`HealthProfile.SmokingFrequency.DAILY` as any, {
          value: "",
        }),
      };
    }

    return newHealthMeasurementItem.valueAsJSON;
  }

  function fetchHealthProfileAndUpdateLocalState() {
    setIsFetchingHealthProfile(true);
    getHealthProfile()
      .then((response: any) => {
        setHealthMeasurementMetadata((previousState: any) => {
          return {
            ...previousState,
            items: previousState?.items?.map((healthMeasurementItem: any) => {
              const foundHealthMeasurement = response?.items?.find(
                (item: any) => {
                  return (
                    item.questionType === healthMeasurementItem.questionType
                  );
                }
              );

              if (
                healthMeasurementItem.unitOverride ||
                healthMeasurementItem.unitOverride === ""
              ) {
                foundHealthMeasurement.valueAsJSON = convertStringToJSON(
                  `{"unit":"${healthMeasurementItem.unitOverride}","value":${foundHealthMeasurement.data}}`
                );
              } else {
                foundHealthMeasurement.valueAsJSON = convertStringToJSON(
                  foundHealthMeasurement.data
                );
              }

              if (foundHealthMeasurement.questionType === "SmokingFrequency") {
                foundHealthMeasurement.valueAsJSON = getSmokingStatusAndValue(
                  foundHealthMeasurement,
                  response
                );
              }

              if (
                foundHealthMeasurement.questionType ===
                  HealthMeasurementIdentifier.BloodType &&
                foundHealthMeasurement?.valueAsJSON?.value?.toUpperCase() ===
                  "UNKNOWN"
              ) {
                foundHealthMeasurement.valueAsJSON.value = t(
                  "HealthProfile.BloodType.unknown.label"
                );
              }

              return {
                ...healthMeasurementItem,
                ...foundHealthMeasurement,
              };
            }),
          };
        });
        setIsFetchingHealthProfile(false);
      })
      .catch(() => {
        setIsFetchingHealthProfile(false);
        showSnackbar({
          message: t("common.genericErrorMessage"),
          animate: true,
          type: "error",
          direction: "down",
          duration: 5000,
          position: { horizontal: "center", vertical: "top" },
        });
        navigate("/home/my-health/health-profile");
      });
  }

  useEffect(() => {
    const healthMeasurementMetadata =
      healthMeasurementsMetadata[healthMeasurementIdentifier] || null;

    if (healthMeasurementMetadata) {
      setHealthMeasurementMetadata(healthMeasurementMetadata);
      fetchHealthProfileAndUpdateLocalState();
    } else {
      navigate("/home/my-health/health-profile");
    }
  }, [healthMeasurementIdentifier]);

  return (
    <>
      <Stack spacing={4}>
        <HealthMeasurementDetailHeader
          healthMeasurementIdentifier={healthMeasurementIdentifier}
          displaySubHeading={healthMeasurementMetadata?.displaySubHeading}
        />

        {isFetchingHealthProfile === true && (
          <Stack alignItems="center">
            <LoadingSpinner />
          </Stack>
        )}

        {isFetchingHealthProfile === false && (
          <Stack spacing={2}>
            <HealthMeasurementDetailVerificationStatus
              healthMeasurementDetailMetaData={healthMeasurementMetadata?.items}
            />

            {healthMeasurementMetadata?.items &&
              healthMeasurementMetadata?.items.length > 0 && (
                <Stack spacing={2}>
                  {healthMeasurementMetadata?.items
                    .filter((item) => item.shouldDisplay)
                    .map((healthMeasurementItem) => (
                      <Panel key={healthMeasurementItem.questionId}>
                        <Stack alignItems={"center"}>
                          {healthMeasurementItem?.valueAsJSON?.value && (
                            <>
                              {healthMeasurementItem.numberOfTicks && (
                                <>
                                  <Stack
                                    sx={{
                                      position: "relative",
                                      width: "100%",
                                      alignItems: "center",
                                      pb: 4,
                                      pt: 2,
                                    }}
                                  >
                                    <Typography variant="h4">
                                      {t(
                                        `HealthProfile.${healthMeasurementItem.questionType}.detail.gauge.title` as any
                                      )}
                                    </Typography>
                                    {healthMeasurementItem.gaugeInfoModal && (
                                      <IconLoader
                                        icon="InformationSymbolInCircleIcon"
                                        onClick={() =>
                                          openInfoModal(
                                            healthMeasurementItem.questionType,
                                            healthMeasurementItem.gaugeInfoModal
                                              ?.isFooterContentVisible
                                          )
                                        }
                                        sx={{
                                          position: "absolute",
                                          top: (theme) => theme.spacing(1),
                                          right: (theme) => theme.spacing(1),
                                          fontSize: 25,
                                          color: (theme) =>
                                            theme.palette.primary[700],
                                        }}
                                      />
                                    )}
                                  </Stack>
                                  <HealthMeasurementGauge
                                    healthMeasurementIdentifier={
                                      healthMeasurementItem.questionType
                                    }
                                    value={
                                      healthMeasurementItem?.valueAsJSON?.value
                                    }
                                    unit={
                                      healthMeasurementItem?.valueAsJSON?.unit
                                    }
                                    valueRange={replaceValuesWithColors(
                                      getHealthMeasurementValueRange(
                                        healthMeasurementItem.questionType
                                      ),
                                      healthMeasurementItem.questionType
                                    )}
                                    numberOfTicks={
                                      healthMeasurementItem.numberOfTicks
                                    }
                                  />
                                </>
                              )}

                              {healthMeasurementItem.updateComponentState && (
                                <HealthMeasurementDetailUpdate
                                  healthMeasurementIdentifier={
                                    healthMeasurementIdentifier
                                  }
                                  healthMeasurementMetadata={
                                    healthMeasurementItem
                                  }
                                  isUserEditable={getIsUserEditableStatus(
                                    healthMeasurementMetadata?.items
                                  )}
                                />
                              )}
                            </>
                          )}
                        </Stack>
                      </Panel>
                    ))}
                </Stack>
              )}
          </Stack>
        )}

        {gaugeInfoModalState.isOpen && (
          <HealthMeasurementDetailModal
            isOpen={gaugeInfoModalState.isOpen}
            onClose={closeInfoModal}
            extras={{
              healthMeasurementIdentifier: gaugeInfoModalState.questionType,
              isFooterContentVisible:
                gaugeInfoModalState.isFooterContentVisible,
            }}
          />
        )}
      </Stack>
      <BottomNavigation />
    </>
  );
}
