import React, { createContext, useState, useCallback, useMemo } from "react";

interface Snackbar {
  message: string;
  type?: "error" | "warning" | "info" | "success";
  visible: boolean;
  duration?: number;
  position?: {
    horizontal: "center" | "right" | "left";
    vertical: "top" | "bottom";
  };
  direction?: "left" | "right" | "up" | "down";
  animate?: boolean;
}

const initialSnackbarState: Snackbar = {
  message: "",
  type: "info",
  visible: false,
  duration: 3000,
  position: {
    vertical: "top",
    horizontal: "center",
  },
  direction: "down",
  animate: false,
};

export const SnackbarContext = createContext<{
  hideSnackbar: Function;
  showSnackbar: (arg: Omit<Snackbar, "visible">) => void;
  snackbarState: Snackbar;
}>({
  hideSnackbar: () => {},
  showSnackbar: () => {},
  snackbarState: initialSnackbarState,
});

interface Props {
  children: React.ReactNode;
}

export const SnackbarProvider = ({ children }: Props) => {
  const [snackbarState, setToast] = useState(initialSnackbarState);

  const showSnackbar = useCallback((args: Omit<Snackbar, "visible">) => {
    setToast({ ...initialSnackbarState, visible: true, ...args });
  }, []);

  const hideSnackbar = useCallback(() => {
    setToast({ ...snackbarState, visible: false });
  }, [snackbarState]);

  const memoizedSnackbarValue = useMemo(
    () => ({
      hideSnackbar,
      showSnackbar,
      snackbarState,
    }),
    [hideSnackbar, showSnackbar, snackbarState]
  );

  return (
    <SnackbarContext.Provider value={memoizedSnackbarValue}>
      {children}
    </SnackbarContext.Provider>
  );
};
