import { Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import IconLoader from "../../../../../Common/components/IconLoader";
import Panel from "../../../../../Common/components/Panel";
import HealthMeasurementDetailModal from "../../../HealthMeasurementDetailModal";

export default function HealthMeasurementDetailHeader({
  healthMeasurementIdentifier,
  displaySubHeading,
}: {
  healthMeasurementIdentifier: string | undefined;
  displaySubHeading?: boolean;
}) {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  function onModalClose() {
    setIsModalOpen(false);
  }

  function onModalOpenButtonClick() {
    setIsModalOpen(true);
  }

  return (
    <Stack spacing={2}>
      <Stack
        direction={"row"}
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Stack spacing={1}>
          <Typography variant="h2">
            {t(
              `HealthProfile.${healthMeasurementIdentifier}.detail.heading` as any
            )}
          </Typography>

          {displaySubHeading && (
            <Typography variant="h4">
              {t(
                `HealthProfile.${healthMeasurementIdentifier}.detail.subHeading` as any
              )}
            </Typography>
          )}
        </Stack>

        <Stack>
          <Panel padding={0}>
            <Stack
              component="button"
              sx={{ background: "transparent", border: "none", padding: 2 }}
              aria-label={t("HealthMeasurementDetail.Modal.MoreInfoCTA")}
              onClick={onModalOpenButtonClick}
            >
              <IconLoader icon="InformationSymbolInCircleIcon" />
            </Stack>
          </Panel>
        </Stack>
      </Stack>

      <HealthMeasurementDetailModal
        isOpen={isModalOpen}
        onClose={onModalClose}
        extras={{
          healthMeasurementIdentifier: healthMeasurementIdentifier,
        }}
      />
      <Typography variant="body1" sx={{ mb: 4 }}>
        {t(
          `HealthProfile.${healthMeasurementIdentifier}.detail.subtitle` as any
        )}
      </Typography>
    </Stack>
  );
}
